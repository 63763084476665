<template>
  <v-card class="ml-15" elevation="0">
    <v-card-title class="d-flex flex-column pb-6">
      <div class="d-flex justify-start" style="width: 100%">
        <p class="text-body-1 ml-6 ma-0 font-weight-bold">Mi empresa</p>
        <v-icon class="mr-2 ml-10" small color="#6F50FF"
          >mdi-help-circle-outline</v-icon
        >
        <p class="text-caption ma-0">
          Crea y administra las áreas de tu empresa
        </p>
      </div>
      <div class="d-flex mt-4" style="width: 100%">
        <div class="d-flex align-center mr-16" style="width: 30%">
          <v-icon small class="ml-5" color="#8739EB">mdi-magnify</v-icon>
          <v-divider class="mx-3" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            color="#6200EE"
            class="caption shrink ml-2 pl-3 ma-0 pa-0 mb-2 text-capitalize"
            style="width: 100%; background: #f7f8fa"
            single-line
            hide-details
          >
            <template v-slot:label class="caption">
              <span class="caption ml-2">Buscar</span>
            </template>
          </v-text-field>
        </div>
        <div class="d-flex ml-16">
          <div class="d-flex align-center mr-10">
            <p
              class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded mr-2"
              style="background: #f2f4f7; color: #a4afc0"
            >
              {{ avaliableZones() }}
            </p>
            <p
              v-if="numerzones !== 0"
              class="text-caption ma-0 font-weight-light"
            >
              Zonas Disponibles
            </p>
            <p
              v-else
              class="text-caption ma-0 font-weight-light red--text font-weight-medium"
            >
              Sin Zonas Disponibles
            </p>
            <v-divider vertical class="mx-3"></v-divider>
          </div>
          <div class="d-flex align-center">
            <p class="text-caption ma-0 font-weight-lighter">
              Nivel de usuario
            </p>
            <v-divider vertical class="mx-3"></v-divider>
            <v-icon small color="#6E80F8" class="mr-2"
              >mdi-account-outline</v-icon
            >
            <p class="text-caption ma-0 font-weight-bold">
              {{ capitalizeFirstLetter(userType.toLowerCase()) }}
            </p>
          </div>
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-alert
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="createdZone"
      >
      <p class="ma-0 pa-0 caption">¡Zona creada con éxito!</p>
    </v-alert>
    <v-alert
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="editedZone"
      >
      <p class="ma-0 pa-0 caption">¡Zona editada con éxito!</p>
    </v-alert>
    <v-alert
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="deletedZone"
      >
      <p class="ma-0 pa-0 caption">¡Zona eliminada con éxito!</p>
    </v-alert>
    <v-alert
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="archivedZone"
      >
      <p class="ma-0 pa-0 caption">¡Zona archivada con éxito!</p>
    </v-alert>
    <v-alert
      dense
      text
      type="success"
      transition="scale-transition"
      v-if="unarchivedZone"
      >
      <p class="ma-0 pa-0 caption">¡Zona desarchivada con éxito!</p>
    </v-alert>
    <v-card elevation="0" class="pb-5 pa-4" width="100%">
      <v-container fluid grid-list-md class="cont-items">
        <v-layout row wrap d-flex justify-start>
          <v-flex d-flex justify-start xs12 sm12 md3>
            <v-card
              class="d-flex align-center justify-center"
              style="border: 1px dashed #babbc4"
              elevation="0"
            >
              <div class="d-flex flex-column align-center justify-center">
                <v-card elevation="0" class="d-flex flex-column align-center pt-3">
                  <v-icon x-large color="#8739EB">mdi-plus-circle</v-icon>

                  <p
                    class="text-caption ma-0 mt-3 font-weight-bold mb-2"
                    style="color: #babbc4"
                  >
                    Crea una zona
                  </p>
                  <p
                    style="color: #babbc4"
                    class="text-caption ma-0 pl-6 pr-6 font-weight-light text-center"
                  >
                    Gestiona de manera práctica y eficiente las áreas y los
                    colaboradores de tu empresa.
                  </p>
                    <v-btn
                    rounded
                    elevation="0"
                    color="#43425D"
                    class="d-flex alig-center mt-4 mb-5"
                    @click="dialogCreateZone = true"
                    :disabled="numerzones === 0"
                  >
                    <v-icon color="#fff" small class="mr-2"
                      >mdi-account-multiple-plus-outline</v-icon
                    >
                    <p
                      style="color: #fff"
                      class="ma-0 pa-0 caption text-capitalize text-white"
                    >
                      Agregar zona
                    </p>
                  </v-btn>
                </v-card>
                <v-dialog v-model="dialogCreateZone" persistent max-width="350">
                  <v-card class="pa-6" tile>
                    <div class="d-flex justify-start">
                      <p class="text-body-1 ma-0 font-weight-bold mb-4">
                        Crear zona
                      </p>
                    </div>
                    <div>
                      <v-form
                        ref="formCreateZone"
                        v-model="valid"
                        lazy-validation
                        class="d-flex flex-column"
                      >
                        <v-text-field
                          color="#8739EB"
                          class="input-nv caption"
                          v-model="zoneName"
                          :rules="[(v) => !!v || 'Este campo es requerido']"
                        >
                          <template v-slot:label class="caption">
                            <v-icon class="icon-input" small>
                              mdi-map-marker-outline
                            </v-icon>
                            <span class="caption ml-1">Nombre</span>
                          </template>
                        </v-text-field>

                        <v-select
                          :items="countries"
                          v-model="countryName"
                          :rules="[(v) => !!v || 'Este campo es requerido']"
                          single-line
                          class="mt-n1 mb-4 caption ma-0 font-weight-light"
                          color="#8739EB"
                        >
                          <template v-slot:label>
                            <p
                              class="caption ma-0 pa-0"
                              style="color: #1d305180"
                            >
                              <v-icon class="icon-input" small>
                                mdi-earth
                              </v-icon>
                              País
                            </p>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <p class="caption">{{ item }}</p>
                              <v-divider></v-divider>
                            </v-list-item-content>
                          </template>
                        </v-select>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            rounded
                            small
                            elevation="0"
                            color="#E3E9EF9E"
                            style="color: #a4a5ad"
                            class="pl-8 pr-8 text-capitalize"
                            @click="reset()"
                            >Cerrar</v-btn
                          >
                          <v-btn
                            rounded
                            small
                            elevation="0"
                            color="#43425D"
                            style="color: #fff"
                            :disabled="!valid"
                            class="pl-8 pr-8 mr-5 text-capitalize"
                            @click="
                              addAvaliableZones(),
                                logCreating(zoneName, countryName),
                                (dialogCreateZone = false)
                            "
                            >Guardar</v-btn
                          >
                        </v-card-actions>
                      </v-form>
                    </div>
                  </v-card>
                </v-dialog>
              </div>
            </v-card>
          </v-flex>
          <v-flex
            v-for="zone in zones"
            :key="zone.zone_id"
            d-flex
            justify-start
            xs12
            sm12
            md3
          >
            <v-card
              class="d-flex align-center justify-center flex-column"
              style="border: 1px dashed #babbc4"
              elevation="0"
              width="100%"
            >
              <v-skeleton-loader
                type="image"
                class="ma-0 pa-0 text-left"
                style="width: 100%"
                :loading="loading"
              >
                <div style="width: 100%" class="d-flex justify-end mr-3">
                  <v-menu
                    transition="slide-y-transition"
                    :offset-x="offset"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon dark v-bind="attrs" v-on="on">
                        <v-icon color="#8739EB"
                          >mdi-chevron-down-circle-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title class="caption">
                          <v-btn
                            text
                            class="d-flex justify-start ma-0"
                            width="100%"
                            @click="logArchiving(`${zone.zone_id}`)"
                          >
                            <v-icon class="mr-4" small color="#6F50FF">
                              mdi-archive-arrow-down-outline
                            </v-icon>
                            <p
                              class="text-caption pa-0 ma-0 text-capitalize font-weight-light"
                              style="color: #000"
                            >
                              Archivar
                            </p>
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item>
                        <v-dialog v-model="dialog" persistent max-width="350">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              text
                              class="d-flex justify-start ma-0"
                              width="100%"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                (zoneName = `${zone.company_zone_name}`),
                                  (countryName = `${zone.company_zone_country}`)
                              "
                            >
                              <v-icon class="mr-4" small color="#6F50FF">
                                mdi-square-edit-outline
                              </v-icon>
                              <p
                                class="text-caption pa-0 ma-0 text-capitalize font-weight-light"
                                style="color: #000"
                              >
                                Editar zona
                              </p>
                            </v-btn>
                          </template>
                          <v-card class="pa-4">
                            <div class="d-flex justify-start">
                              <p
                                class="text-body-1 ma-0 font-weight-bold ml-4 mb-5"
                              >
                                Editar zona
                              </p>
                            </div>
                            <div>
                              <v-text-field
                                color="#6200EE"
                                class="input-nv caption"
                                v-model="zoneName"
                              >
                                <template v-slot:label class="caption">
                                  <v-icon class="icon-input" small>
                                    mdi-map-marker-outline
                                  </v-icon>
                                  <span class="caption ml-1">Nombre</span>
                                </template>
                              </v-text-field>
                              <v-select
                                :items="countries"
                                v-model="countryName"
                                single-line
                                class="mt-n1 mb-4 caption ma-0 font-weight-light"
                                color="#8739EB"
                              >
                                <template v-slot:label>
                                  <p
                                    class="caption ma-0 pa-0"
                                    style="color: #1d305180"
                                  >
                                    <v-icon class="icon-input" small>
                                      mdi-earth
                                    </v-icon>
                                    País
                                  </p>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <p class="caption">{{ item }}</p>
                                    <v-divider></v-divider>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </div>
                            <v-card-actions>
                              <v-spacer></v-spacer>

                              <v-btn
                                rounded
                                small
                                elevation="0"
                                color="#E3E9EF9E"
                                style="color: #a4a5ad"
                                class="pl-8 pr-8 text-capitalize"
                                @click="dialog = false"
                                >Cerrar</v-btn
                              >
                              <v-btn
                                rounded
                                small
                                elevation="0"
                                color="#43425D"
                                style="color: #fff"
                                class="pl-8 pr-8 mr-5 text-capitalize"
                                @click="
                                  logUpdating(
                                    `${zone.zone_id}`,
                                    zoneName,
                                    countryName
                                  ),
                                    (dialog = false)
                                "
                                >Guardar</v-btn
                              >
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-title class="caption">
                          <v-btn
                            text
                            class="d-flex justify-start ma-0"
                            width="100%"
                            @click="
                              logDeleting(`${zone.zone_id}`),
                                substractAvaliableZones()
                            "
                          >
                            <v-icon class="mr-4" small color="#6F50FF">
                              mdi-trash-can-outline
                            </v-icon>
                            <p
                              class="text-caption pa-0 ma-0 text-capitalize font-weight-light"
                              style="color: #000"
                            >
                              Eliminar zona
                            </p>
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
                <div class="d-flex flex-column align-center">
                  <v-avatar color="#8739EB" size="45">
                    <span class="white--text text-h5">R</span>
                  </v-avatar>
                  <p class="text-caption ma-0 font-weight-bold mt-3 mb-2">
                    {{ zone.company_zone_name }}
                  </p>
                  <div
                    class="text-caption font-weight-light d-flex align-center"
                  >
                    <p class="ma-0 pa-0">
                      {{ zone.zone_total_candidates }} miembros
                    </p>
                    <v-divider vertical class="mx-1"></v-divider>
                    <p class="ma-0 pa-0">Público</p>
                  </div>
                  <div class="mb-2">
                    <v-container fluid grid-list-md class="mt-2">
                      <v-layout row wrap d-flex justify-start>
                        <v-flex d-flex justify-start xs12 sm12 md3>
                          <v-avatar color="indigo" size="20">
                            <span class="white--text caption">M</span>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </div>
                  <!-- <v-divider width="100%"></v-divider>
                  <div class="d-flex align-content-center mt-4 pb-4">
                    <v-icon small color="#6E80F8" class="mr-1"
                      >mdi-map-marker-outline</v-icon
                    >
                    <p class="text-caption font-weight-light ma-0 pa-0">
                      12 venues asignados
                    </p>
                  </div> -->
                </div>
              </v-skeleton-loader>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-divider class="pt-8"></v-divider>
    <v-card color="#F5F5F5" class="pb-10" tile>
      <v-card-title class="">
        <v-icon color="#8739EB" small>mdi-archive-arrow-down-outline</v-icon>
        <p class="text-body-1 ml-2 ma-0 font-weight-bold">Zonas archivadas</p>
      </v-card-title>
      <v-container fluid grid-list-md>
        <v-layout row wrap d-flex justify-start>
          <v-flex
            v-for="zone in zonesArchive"
            :key="zone.zone_id"
            d-flex
            justify-start
            xs12
            sm12
            md3
          >
            <v-skeleton-loader
              type="list-item-avatar-three-line"
              class="ma-0 pa-0"
              style="width: 18rem"
              :loading="loading"
            >
              <v-card class="d-flex pa-5" tile elevation="0">
                <div class="d-flex align-center">
                  <v-avatar color="#8739EB" size="36">
                    <span class="white--text text-h5">R</span>
                  </v-avatar>
                </div>
                <div class="d-flex flex-column align-start ml-3">
                  <div class="d-flex">
                    <div class="d-flex flex-column align-start">
                      <p class="text-caption ma-0 pa-0 font-weight-bold">
                        {{ zone.company_zone_name }}
                      </p>
                      <div
                        class="text-caption font-weight-light d-flex align-center"
                      >
                        <p class="ma-0 pa-0">
                          {{ zone.zone_total_candidates }} miembros
                        </p>
                        <v-divider vertical class="mx-1"></v-divider>
                        <p class="ma-0 pa-0">Público</p>
                      </div>
                    </div>
                    <div class="d-flex align-center ml-6">
                      <v-menu top :offset-y="offset">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon dark v-bind="attrs" v-on="on">
                            <v-icon color="#A4A5AD"
                              >mdi-chevron-down-circle-outline</v-icon
                            >
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item>
                            <v-list-item-title class="caption">
                              <v-btn
                                text
                                class="text-none caption font-weight-light text-left"
                                width="100%"
                                @click="logUnarchiving(`${zone.zone_id}`)"
                                >Desarchivar</v-btn
                              >
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-title class="caption">
                              <v-btn
                                text
                                class="text-none caption font-weight-light text-left"
                                width="100%"
                                elevation="0"
                                @click="
                                  logDeleting(`${zone.zone_id}`),
                                    substractAvaliableZones()
                                "
                                >Eliminar</v-btn
                              >
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-skeleton-loader>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <div v-if="zonesStateLoading === true">
      <transition name="modal-fade">
        <div class="modal-backdrop">
          <div
            class="modal"
            role="dialog"
            area-labelledby="modalTitle"
            aria-describedby="modalDesc"
          >
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">espere un momento...</h4>
              <v-progress-linear
                color="#6E80F8"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <!-- <v-progress-circular
              color="white"
              size="40"
              width="6"
              indeterminate
            ></v-progress-circular> -->
            </v-col>
          </div>
        </div>
      </transition>
    </div>
  </v-card>
</template>

<script>
import CardCreateZone from "../../components/MyBusiness/CardCreateZone.vue";
import ArchivedCard from "../../components/MyBusiness/ArchivedCard.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import ENUM from "@/store/enums";
import LoadCard from "../../mixins/LoadCard";

export default {
  components: { CardCreateZone, ArchivedCard },
  mixins: [LoadCard],
  data() {
    return {
      dialogCreateZone: false,
      dialog: false,
      countryName: "",
      zoneName: "",
      offset: true,
      valid: false,
      rules: [(v) => !!v || "* este campo es requerido"],
      search: "",
      maximum_zones: 0,
      avaliable_zones: 0,
      numerzones: 0,
      isAv: false,
      countries: ["México", "USA", "Canadá"],
    };
  },
  methods: {
    ...mapActions([
      "deleteZones",
      "archiveZones",
      "updateZone",
      "createZone",
      "unarchiveZones",
    ]),
    logDeleting(taskId) {
      this.deleteZones(taskId);
    },
    logArchiving(taskId) {
      this.archiveZones(taskId);
    },
    logUpdating(zone_id, zoneName, countryName) {
      this.updateZone({ zone_id, zoneName, countryName });
    },
    logUnarchiving(taskId) {
      this.unarchiveZones(taskId);
    },
    logCreating(zoneName, countryName) {
      if (this.$refs.formCreateZone.validate()) {
        const payload = {
          company_zone_country: countryName,
          company_zone_name: zoneName,
        };
        this.reset();
        this.createZone(payload);
      }
    },
    reset() {
      (this.dialogCreateZone = false), this.$refs.formCreateZone.reset();
    },
    avaliableZones() {
      this.numerzones = this.maximum_zones - this.avaliable_zones;
      return this.numerzones;
    },
    substractAvaliableZones() {
      this.avaliable_zones = this.avaliable_zones - 1;
    },
    addAvaliableZones() {
      this.avaliable_zones = this.avaliable_zones + 1;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  computed: {
    ...mapGetters(["getCompany", "userType"]),
    ...mapState({
      zonesState: (state) => state.zones.status,
    }),
    zonesStateLoading() {
      return this.zonesState === ENUM.LOADING;
    },
    zones() {
      let zones = [];
      zones = this.$store.getters.getAllZones.filter(
        (zone) => zone.zone_is_active && !zone.zone_is_archive
      );
      return zones.filter((zones) => {
        return zones.company_zone_name
          .toLowerCase()
          .match(this.search.toLowerCase());
      });
    },
    zonesArchive() {
      return this.$store.getters.getAllZones.filter(
        (zone) => zone.zone_is_active && zone.zone_is_archive
      );
    },
    company() {
      let company = [];
      company = this.$store.getters.getCompany;
      return company;
    },
    createdZone(){
      return this.$store.state.zones.createZone
    },
    editedZone(){
      return this.$store.state.zones.editeZone
    },
    deletedZone(){
      return this.$store.state.zones.deleteZone
    },
    archivedZone(){
      return this.$store.state.zones.archiveZone
    },
    unarchivedZone(){
      return this.$store.state.zones.unarchiveZone
    }
  },
  watch: {
    getCompany() {
      this.maximum_zones = this.getCompany.company_maximum_zones;
      this.avaliable_zones = this.getCompany.company_zones;
      this.numerzones =
        this.getCompany.company_maximum_zones - this.getCompany.company_zones;
    },
    createdZone(){
      setTimeout(() => this.$store.commit("CREATED_ZONE", false), 3000);
    },
    editedZone(){
      setTimeout(() => this.$store.commit("EDITED_ZONE", false), 3000);
    },
    deletedZone(){
      setTimeout(() => this.$store.commit("DELETED_ZONE", false), 3000);
    },
    archivedZone(){
      setTimeout(() => this.$store.commit("ARCHIVED_ZONE", false), 3000);
    },
    unarchivedZone(){
      setTimeout(() => this.$store.commit("UNARCHIVED_ZONE", false), 3000);
    }
  },
  beforeCreate() {
    this.$store.dispatch("requestsAllZones");
    this.$store.dispatch("requestsAllCompanies");
  },
};
</script>

<style>
.fa {
  height: 8rem !important ;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
h4 {
  color: #fff;
  letter-spacing: 2px;
}
</style>
