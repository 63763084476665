<template>
  <v-card
    class="d-flex align-center justify-center"
    style="border: 1px dashed #babbc4"
    elevation="0"
  >
    <div class="d-flex flex-column align-center pa-8">
      <v-icon x-large color="#8739EB">mdi-plus-circle</v-icon>
      <p
        class="text-caption ma-0 font-weight-bold mt-3 mb-2"
        style="color: #babbc4"
      >
        Crea una zona
      </p>
      <p class="text-caption ma-0 font-weight-light">
        Gestiona de manera práctica y eficiente las áreas y los colaboradores de
        tu empresa.
      </p>
      <v-dialog v-model="dialog" persistent max-width="350">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            elevation="0"
            color="#43425D"
            class="d-flex alig-center mt-4"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon small color="#FFF" class="mr-2"
              >mdi-account-multiple-plus-outline</v-icon
            >
            <p class="text-none caption ma-0 pa-0" style="color: #fff">
              Agregar zona
            </p>
          </v-btn>
        </template>
        <v-card class="pa-4">
          <div class="d-flex justify-start">
            <p class="text-body-1 ma-0 font-weight-bold ml-4 mb-5">
              Crear zona
            </p>
          </div>
          <div>
            <v-text-field color="#6200EE" class="input-nv" v-model="zoneName">
              <template v-slot:label class="caption">
                <v-icon class="icon-input" small>
                  mdi-map-marker-outline
                </v-icon>
                <span class="caption ml-2">Nombre</span>
              </template>
            </v-text-field>
            <v-text-field color="#6200EE" class="input-nv" v-model="countryName">
              <template v-slot:label class="caption">
                <v-icon class="icon-input" small>
                  mdi-map-marker-outline
                </v-icon>
                <span class="caption ml-2">País</span>
              </template>
            </v-text-field>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              rounded
              small
              elevation="0"
              color="#E3E9EF9E"
              style="color: #a4a5ad"
              class="pl-8 pr-8 text-capitalize"
              @click="dialog = false"
              >Cerrar</v-btn
            >
            <v-btn
              rounded
              small
              elevation="0"
              color="#43425D"
              style="color: #fff"
              class="pl-8 pr-8 mr-5 text-capitalize"
              @click="logCreating(zoneName, countryName), addAvaliableZones(), dialog =false"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data(){
        return{
            dialog: false,
            countryName: '',
            zoneName: '',
        }
    },
  methods: {
      ...mapActions(["createZone"]),
      
      logCreating(zoneName, countryName) {
      const payload = {
        company_zone_country: countryName, 
        company_zone_name: zoneName,
      }
      this.createZone(payload);
      }
    },

    addAvaliableZones(){
      this.avaliable_zones = this.avaliable_zones + 1
    },

  watch: {
    getCompany(){
      this.maximum_zones = this.getCompany.company_maximum_zones
      this.avaliable_zones = this.getCompany.company_zones
    }
  },
};
</script>

<style>
.fa {
  height: 8rem !important ;
}
</style>
