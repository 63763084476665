<template>
  <v-container fluid grid-list-md>
    <v-layout row wrap d-flex justify-start>
      <v-flex
        v-for="zone in zones"
        :key="zone.zone_id"
        d-flex
        justify-start
        xs12
        sm12
        md3
      >
          <v-skeleton-loader
                type="list-item-avatar-three-line"
                class="ma-0 pa-0"
                style="width: 18rem"
                :loading="loading"
              >
        <v-card class="d-flex pa-5" tile elevation="0">
          <div class="d-flex align-center">
            <v-avatar color="#8739EB" size="36">
              <span class="white--text text-h5">R</span>
            </v-avatar>
          </div>
          <div class="d-flex flex-column align-start ml-3">
            <div class="d-flex">
              <div class="d-flex flex-column align-start">
                <p class="text-caption ma-0 pa-0 font-weight-bold">
                  {{ zone.company_zone_name }}
                </p>
                <div class="text-caption font-weight-light d-flex align-center">
                  <p class="ma-0 pa-0">
                    {{ zone.zone_total_candidates }} miembros
                  </p>
                  <v-divider vertical class="mx-1"></v-divider>
                  <p class="ma-0 pa-0">Público</p>
                </div>
              </div>
              <div class="d-flex align-center ml-6">
                <v-menu top :offset-y="offset">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon dark v-bind="attrs" v-on="on">
                      <v-icon color="#A4A5AD"
                        >mdi-chevron-down-circle-outline</v-icon
                      >
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="caption">
                        <v-btn
                          text
                          class="text-none caption font-weight-light text-left"
                          width="100%"
                          @click="
                            logUnarchiving(`${zone.zone_id}`);
                            forceUpdate;
                          "
                          >Desarchivar</v-btn
                        >
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="caption">
                        <v-btn
                          text
                          class="text-none caption font-weight-light text-left"
                          width="100%"
                          elevation="0"
                          @click="logDeleting(`${zone.zone_id}`)"
                          >Eliminar</v-btn
                        >
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </v-card>
          </v-skeleton-loader>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import LoadCard from "../../mixins/LoadCard";

export default {
  mixins: [LoadCard],
  data: () => ({
    offset: true,
  }),
  computed: {
    zones() {
      return this.$store.getters.getAllZones.filter(
        (zone) => zone.zone_is_active && zone.zone_is_archive
      );
    },
  },
  methods: {
    ...mapActions(["deleteZones"]),
    ...mapActions(["unarchiveZones"]),

    logDeleting(taskId) {
      this.deleteZones(taskId);
    },
    logUnarchiving(taskId) {
      this.unarchiveZones(taskId);
    },
  },

  beforeCreate() {
    this.$store.dispatch("requetsAllZones");
  },
};
</script>
<style></style>
